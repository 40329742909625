










































import { Component, Vue } from 'vue-property-decorator';

import { nameRules } from './validators';
import { dispatchGetPlugsCheckerTasks, dispatchUpdatePlugsCheckerTask } from '@/store/plugs-checker-tasks/actions';
import { IPlugsCheckerTaskUpdate } from '@/interfaces/plugs-checker-tasks';
import { readOnePlugsCheckerTask } from '@/store/plugs-checker-tasks/getters';

@Component
export default class AvitoEditTask extends Vue {
  public valid = false;
  public name = '';
  public description = '';
  public nameRules = nameRules;

  public async mounted() {
    await dispatchGetPlugsCheckerTasks(this.$store);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.task) {
      this.name = this.task.name;
      this.description = this.task.description;
    } else this.name = this.description = '';
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const updatedTask: IPlugsCheckerTaskUpdate = {
        name: this.name,
        description: this.description || undefined,
      };
      await dispatchUpdatePlugsCheckerTask(this.$store, { id: this.task!.id, task: updatedTask });
      this.$router.push('/main/plugs-checker-tasks');
    }
  }

  get task() {
    return readOnePlugsCheckerTask(this.$store)(+this.$router.currentRoute.params.id);
  }

  public isNew() {
    return this.task?.status === 'NEW';
  }

}
